// extracted by mini-css-extract-plugin
export var root = "PlasmicTopSection-module--root--2B2t-";
export var box__uc4G5 = "PlasmicTopSection-module--box__uc4G5--2qt4l";
export var box__beHq = "PlasmicTopSection-module--box__beHq--f_ty8";
export var box__lR3PP = "PlasmicTopSection-module--box__lR3PP--1xeRE";
export var box__h7V0C = "PlasmicTopSection-module--box__h7V0C--1poQZ";
export var box__wLt2A = "PlasmicTopSection-module--box__wLt2A--5pALP";
export var box__hUTwB = "PlasmicTopSection-module--box__hUTwB--2TADL";
export var box__uSnp = "PlasmicTopSection-module--box__uSnp--3bUI1";
export var linkButton__vgcPw = "PlasmicTopSection-module--linkButton__vgcPw--1z8L-";
export var linkButton__m8GiB = "PlasmicTopSection-module--linkButton__m8GiB--2fBZ5";
export var box__mr5Bt = "PlasmicTopSection-module--box__mr5Bt--2wx_R";
export var img = "PlasmicTopSection-module--img--TX3Qd";