// extracted by mini-css-extract-plugin
export var root = "PlasmicPricing-module--root--Kf0oH";
export var box__bs2Wb = "PlasmicPricing-module--box__bs2Wb--1U5PH";
export var header = "PlasmicPricing-module--header--3rvc5";
export var section___7X9X = "PlasmicPricing-module--section___7X9X--1IqVO";
export var box__i1DNx = "PlasmicPricing-module--box__i1DNx--1CQlW";
export var plan__paRa9 = "PlasmicPricing-module--plan__paRa9--SXgqw";
export var box__gtJx = "PlasmicPricing-module--box__gtJx--3XftL";
export var box__o3Hzd = "PlasmicPricing-module--box__o3Hzd--1SUVI";
export var bullet__eZYqQ = "PlasmicPricing-module--bullet__eZYqQ--2wz7_";
export var bullet__fsIjB = "PlasmicPricing-module--bullet__fsIjB--1nHhp";
export var bullet__u9Z5Q = "PlasmicPricing-module--bullet__u9Z5Q--jXb3U";
export var plan__rK5T9 = "PlasmicPricing-module--plan__rK5T9--2Bdnm";
export var box__fSBi = "PlasmicPricing-module--box__fSBi--3nqRD";
export var box__ue1RZ = "PlasmicPricing-module--box__ue1RZ--1TnvG";
export var bullet__gBanu = "PlasmicPricing-module--bullet__gBanu--PZBIY";
export var bullet__eiXu8 = "PlasmicPricing-module--bullet__eiXu8--1NFqj";
export var bullet__fszuf = "PlasmicPricing-module--bullet__fszuf--1Aznj";
export var bullet___5EumN = "PlasmicPricing-module--bullet___5EumN--2hO4T";
export var plan__hL1Y = "PlasmicPricing-module--plan__hL1Y--1vuw9";
export var box___4R2Is = "PlasmicPricing-module--box___4R2Is--3AAkY";
export var box__ypVvm = "PlasmicPricing-module--box__ypVvm--1B3ph";
export var bullet___5Gxzw = "PlasmicPricing-module--bullet___5Gxzw--P8aAO";
export var bullet__xeEdA = "PlasmicPricing-module--bullet__xeEdA--3zmtI";
export var bullet__uxLbu = "PlasmicPricing-module--bullet__uxLbu--tKeIb";
export var section__dTLs = "PlasmicPricing-module--section__dTLs--GO4lj";
export var faq__rB5Z = "PlasmicPricing-module--faq__rB5Z--2M8so";
export var faq___0RVeT = "PlasmicPricing-module--faq___0RVeT--2npgS";
export var faq__mRf8K = "PlasmicPricing-module--faq__mRf8K--Fs0WH";
export var footer = "PlasmicPricing-module--footer--zhosD";