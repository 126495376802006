// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatures-module--root--2CVwz";
export var box__izeab = "PlasmicFeatures-module--box__izeab--2S1Vq";
export var header = "PlasmicFeatures-module--header--aLgU4";
export var section = "PlasmicFeatures-module--section--YTT7P";
export var box__ogCtf = "PlasmicFeatures-module--box__ogCtf--3QaR5";
export var box__g6Gos = "PlasmicFeatures-module--box__g6Gos--e3pom";
export var box__lwaE = "PlasmicFeatures-module--box__lwaE--3aes_";
export var box___0PU58 = "PlasmicFeatures-module--box___0PU58--1aULh";
export var bullet__smsNi = "PlasmicFeatures-module--bullet__smsNi--1ejr0";
export var bullet___62PSg = "PlasmicFeatures-module--bullet___62PSg--1LUc0";
export var bullet__dXpL = "PlasmicFeatures-module--bullet__dXpL--gRkEm";
export var box___0Bw7A = "PlasmicFeatures-module--box___0Bw7A--3ETVE";
export var box__ectXz = "PlasmicFeatures-module--box__ectXz--2he0B";
export var box___2Pyh6 = "PlasmicFeatures-module--box___2Pyh6--M_vwd";
export var img___4CMey = "PlasmicFeatures-module--img___4CMey--2qkWj";
export var img__f14X = "PlasmicFeatures-module--img__f14X--YIlNY";
export var homeCta = "PlasmicFeatures-module--homeCta--_VZ3o";
export var footer = "PlasmicFeatures-module--footer--3Mue7";