// extracted by mini-css-extract-plugin
export var root = "PlasmicFooter-module--root--2Nkxw";
export var box__iQQb = "PlasmicFooter-module--box__iQQb--nYPnE";
export var box__bxbgC = "PlasmicFooter-module--box__bxbgC--1vYvU";
export var box__cQbSu = "PlasmicFooter-module--box__cQbSu--PK_kg";
export var iconLink__z0CR = "PlasmicFooter-module--iconLink__z0CR--1IPhI";
export var svg__jh0Pc = "PlasmicFooter-module--svg__jh0Pc--1jSqd";
export var box__koC9C = "PlasmicFooter-module--box__koC9C--UaljZ";
export var link__tJzMb = "PlasmicFooter-module--link__tJzMb--3wAZX";
export var box__vse3V = "PlasmicFooter-module--box__vse3V--1_rIX";
export var link__yOoq = "PlasmicFooter-module--link__yOoq--17j3T";
export var box__psI = "PlasmicFooter-module--box__psI--287P3";
export var box__pfvKs = "PlasmicFooter-module--box__pfvKs--23PTq";
export var box__tvfQa = "PlasmicFooter-module--box__tvfQa--3TGQE";
export var link__xFeJr = "PlasmicFooter-module--link__xFeJr--27JPr";
export var link__eqc8Y = "PlasmicFooter-module--link__eqc8Y--1w-1o";
export var link___7C5Z6 = "PlasmicFooter-module--link___7C5Z6--3MhVA";
export var link__gmU4V = "PlasmicFooter-module--link__gmU4V--3pz6y";
export var link___7Bg7P = "PlasmicFooter-module--link___7Bg7P--v9FGZ";
export var box__mo1Ks = "PlasmicFooter-module--box__mo1Ks--1K7x-";
export var box__n3Er = "PlasmicFooter-module--box__n3Er--S2YVF";
export var link__zx2UF = "PlasmicFooter-module--link__zx2UF--1M-9Z";
export var link__depQj = "PlasmicFooter-module--link__depQj--2nc-w";
export var link__pW5B = "PlasmicFooter-module--link__pW5B--1Mewf";
export var link__yqa4U = "PlasmicFooter-module--link__yqa4U--2rNQx";
export var link__vxNka = "PlasmicFooter-module--link__vxNka--1z6nI";
export var box__ahm5M = "PlasmicFooter-module--box__ahm5M--OBBz6";
export var box__cp1Bm = "PlasmicFooter-module--box__cp1Bm--3bFz7";
export var link__gnpHr = "PlasmicFooter-module--link__gnpHr--ba7MN";
export var link__qF2Y4 = "PlasmicFooter-module--link__qF2Y4--DSnQc";
export var link__wnOwd = "PlasmicFooter-module--link__wnOwd--2vqH3";
export var link__cD8Kf = "PlasmicFooter-module--link__cD8Kf--2Xf-d";
export var link__b56Xf = "PlasmicFooter-module--link__b56Xf--2X0Fk";
export var box__l3CR = "PlasmicFooter-module--box__l3CR--LG8Ns";
export var box__wluFp = "PlasmicFooter-module--box__wluFp--eBost";
export var box__eaDWo = "PlasmicFooter-module--box__eaDWo--3xwwZ";
export var box__zi1Kt = "PlasmicFooter-module--box__zi1Kt--E11qs";
export var textbox = "PlasmicFooter-module--textbox--2iVbX";
export var button = "PlasmicFooter-module--button--Lj5uW";
export var box__jCpp = "PlasmicFooter-module--box__jCpp--2txSY";
export var box__pFoV1 = "PlasmicFooter-module--box__pFoV1--Urx7f";
export var box__lTs2X = "PlasmicFooter-module--box__lTs2X--23CTR";
export var link__iNjQh = "PlasmicFooter-module--link__iNjQh--1apA1";
export var link__zO0E7 = "PlasmicFooter-module--link__zO0E7--1luIy";
export var box__smaL = "PlasmicFooter-module--box__smaL--35eY0";
export var box__dKuuh = "PlasmicFooter-module--box__dKuuh--1WoOs";
export var iconLink__gzIz8 = "PlasmicFooter-module--iconLink__gzIz8--2TjlG";
export var svg__s1STm = "PlasmicFooter-module--svg__s1STm--3WLHK";
export var iconLink__pzNdU = "PlasmicFooter-module--iconLink__pzNdU--1ZEoy";
export var svg__zz8Gc = "PlasmicFooter-module--svg__zz8Gc--qkUOt";
export var iconLink__kaUq0 = "PlasmicFooter-module--iconLink__kaUq0--36jKj";
export var svg__vfLzy = "PlasmicFooter-module--svg__vfLzy--2U0ZM";