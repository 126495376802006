// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatureCard-module--root--2o9Zx";
export var root__long = "PlasmicFeatureCard-module--root__long--1alJX";
export var box__twOoo = "PlasmicFeatureCard-module--box__twOoo--5QJKY";
export var box__tqLrC = "PlasmicFeatureCard-module--box__tqLrC--D3-kz";
export var svg__tmEeM = "PlasmicFeatureCard-module--svg__tmEeM--QAElx";
export var box__vpfo = "PlasmicFeatureCard-module--box__vpfo--2seCD";
export var box__long__vpfo2NXwC = "PlasmicFeatureCard-module--box__long__vpfo2NXwC--xH59X";
export var slotTitle = "PlasmicFeatureCard-module--slotTitle--f-M6A";
export var slotDescription = "PlasmicFeatureCard-module--slotDescription--ek_se";
export var slotDescription__long = "PlasmicFeatureCard-module--slotDescription__long--1CZ_e";
export var box__xb87L = "PlasmicFeatureCard-module--box__xb87L--39M-T";
export var box__long__xb87L2NXwC = "PlasmicFeatureCard-module--box__long__xb87L2NXwC--3RDfN";
export var svg___2AelR = "PlasmicFeatureCard-module--svg___2AelR--c2c6w";