// extracted by mini-css-extract-plugin
export var root = "PlasmicPlan-module--root--1Vhcn";
export var box__dutcn = "PlasmicPlan-module--box__dutcn--350hS";
export var box__qfywo = "PlasmicPlan-module--box__qfywo--1A_jP";
export var slotName = "PlasmicPlan-module--slotName--3Vdmj";
export var box__ho9PE = "PlasmicPlan-module--box__ho9PE--3O3lO";
export var box__xns4Q = "PlasmicPlan-module--box__xns4Q--aSL1R";
export var bullet__mbn8U = "PlasmicPlan-module--bullet__mbn8U--1AvBM";
export var bullet__a3Io1 = "PlasmicPlan-module--bullet__a3Io1--1Ecgj";
export var bullet__pJn8T = "PlasmicPlan-module--bullet__pJn8T--2jDMS";
export var box__p4KKe = "PlasmicPlan-module--box__p4KKe--J-gec";
export var svg___6Yhx = "PlasmicPlan-module--svg___6Yhx--9hYdt";